import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
// import queryString from 'query-string';

import AggregationBucket from "./AggregationBucket";

const Aggregation = ({ aggregation }) => {
  const aggregationElementId = `collapse-${aggregation.key}-aggregation`;
  return (
    <div className="mb-2">
      <div></div>
      <div
        className="map-sub-heading map-collapsible-heading me-2"
        data-bs-toggle="collapse"
        data-bs-target={`#${aggregationElementId}`}
        aria-expanded="false"
        aria-controls={aggregationElementId}
      >
        <span className="ms-2">{aggregation.name}</span>
      </div>
      <div className="collapse" id={aggregationElementId}>
        {/* <Link
            className="btn btn-outline-warning btn-sm me-1"
            to={{
              pathname: location.pathname,
              // search: search,
              hash: location.hash,
            }}
          >
            Reset
          </Link> */}
        <ul className="list-unstyled ms-4">
          {aggregation.values.slice(0, 1000).map((value) => (
            <AggregationBucket
              key={value.key}
              value={value}
              aggregationName={aggregation.key}
              reverse={aggregation.reverse}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

Aggregation.propTypes = {
  aggregation: PropTypes.object,
};

export default Aggregation;
