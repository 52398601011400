import { useLocation } from "react-router-dom";

import ItemRow from "./ItemRow";
import Pagination from "./Pagination";
import TableHeading from "./TableHeading";
import AggregationList from "../../filterAggregation/AggregationList";
import useResults from "../../hooks/useResults";
import Error from "../../layout/Error";
import Loading from "../../layout/Loading";
import SearchHeader from "../../search/SearchHeader";
import SearchSummary from "../../search/SearchSummary";

const ResultList = ({ entity, title }) => {
  const location = useLocation();
  const showImage = location.pathname.startsWith("/cinema");
  document.title = title;
  const apiPath = `${process.env.REACT_APP_DATA_URL}/${entity}`;

  const {
    items,
    aggregations,
    nestedAggregations,
    pagination,
    columns,
    isLoading,
    error,
  } = useResults(apiPath);

  return (
    <div className="">
      <div className="d-flex flex-row">
        <div className="col">{/* <SearchHeader entity={entity} /> */}</div>
      </div>
      <div className="d-flex flex-row">
        <div className="col">
          <SearchSummary pagination={pagination} />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="">
          <AggregationList aggregations={aggregations} />
          {Object.keys(nestedAggregations).map((key) => {
            const show =
              nestedAggregations[key]["filters"] &&
              Object.keys(nestedAggregations[key]["filters"]).reduce(
                (prev, current) => {
                  return (
                    prev ||
                    nestedAggregations[key]["filters"][current].buckets.length >
                      0
                  );
                },
                false
              );

            return show ? (
              <div key={key}>
                <h1>{nestedAggregations[key]["filterName"]}</h1>
                <AggregationList
                  aggregations={nestedAggregations[key]["filters"]}
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="flex-fill table-responsive">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {error.message !== null ? (
                <Error error={error} />
              ) : (
                <>
                  {/* list and gallery view for each */}
                  <table className="table table-striped table-hover results">
                    <TableHeading columns={columns} showImage={showImage} />
                    <tbody>
                      {items.map((item) => (
                        <ItemRow
                          key={item.slug}
                          columns={columns}
                          item={item}
                          showImage={showImage}
                        />
                      ))}
                    </tbody>
                  </table>
                  {/* <div className="row mb-3">
                    <div className="col">
                      <a className="btn btn-primary" href={dataURL}>
                        Download Summary
                      </a>
                    </div>
                  </div> */}
                </>
              )}
            </>
          )}
          <Pagination pagination={pagination} />
        </div>
      </div>
    </div>
  );
};

export default ResultList;
