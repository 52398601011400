import PropTypes from "prop-types";
import { useSearchParams, Link, useParams } from "react-router-dom";

const TopFilmListItem = ({ films, formattedTimePeriod }) => {
  const [searchParams] = useSearchParams();

  const { n, period, city, rank } = useParams();

  const currentSearchParams = new URLSearchParams(searchParams);

  const sortScreening = new URLSearchParams(searchParams);
  sortScreening.set("filmSort", "screening");
  const sortPopstat = new URLSearchParams(searchParams);
  sortPopstat.set("filmSort", "popstat");

  const pathname = ``;
  const listItems = films.map((film, index) => {
    currentSearchParams.set("active", index + 1);
    currentSearchParams.set("dateFrom", formattedTimePeriod.periodFrom);
    currentSearchParams.set("dateTo", formattedTimePeriod.periodTo);
    return (
      <tr key={film.key} className="">
        <td>
          <Link
            to={{
              pathname: `cinema`,
              search: currentSearchParams.toString(),
            }}
            title={film.film_title.buckets[0].key}
          >
            {film.film_title.buckets[0].key}
          </Link>{" "}
          <Link
            to={{
              pathname: `/film/${film.key}`,
            }}
            title={film.film_title.buckets[0].key}
          >
            (Film Details)
          </Link>
        </td>
        <td>
          {film.film_year.buckets.length > 0 && film.film_year.buckets[0].key}
        </td>
        <td>
          {film.film_country.buckets.length > 0 &&
            film.film_country.buckets[0].key}
        </td>
        <td>{film.doc_count}</td>
        {/* <td>{film.popstat.value.toFixed(2)}</td> */}
      </tr>
    );
  });
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Film</th>
          <th>Year</th>
          <th>Country of Production</th>
          <th>
            <Link
              to={{
                pathname: pathname,
                search: sortScreening.toString(),
              }}
            >
              No of screenings
            </Link>
          </th>
          {/* <th>
            <Link
              to={{
                pathname: pathname,
                search: sortPopstat.toString(),
              }}
            >
              Popstat
            </Link>
          </th> */}
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </table>
  );
};

TopFilmListItem.propTypes = {
  cinemas: PropTypes.array,
};

export default TopFilmListItem;
