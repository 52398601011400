import { Link, useParams } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const About = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - About`;
  const url = `${process.env.REACT_APP_HOMEPAGE}/page/about`;
  const { data, isLoading, error } = useAxios(url, [], []);

  const projectUrl = `${process.env.REACT_APP_HOMEPAGE}/project`;
  const {
    data: projectData,
    isLoading: projectIsLoading,
    error: projectError,
  } = useAxios(projectUrl, [], []);
  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <div className="full-page row">
                <div className="col-6 h-100  d-flex align-items-center">
                  {data.image && (
                    <img
                      className="border rounded-5 mx-auto"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={data.image.src}
                      alt="about tile"
                    />
                  )}
                </div>
                <div className="col-6 h-100 text-break ch-overflow-y">
                  <div className="row">
                    <div className="row mb-1">
                      <h1 className="col-12">About</h1>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        {data.description && (
                          <div className="row">
                            <HtmlSnippet
                              snippet={data.description}
                              className=""
                            />
                          </div>
                        )}
                      </div>
                      {process.env.REACT_APP_PROJECT_CODE === "ch" && (
                        <div className=" ">
                          <h2>
                            These are the projects that so far are included in
                            Cinema Histories:
                          </h2>
                          {projectData
                            .filter((project) => project.slug !== "ch")
                            .map((project) => {
                              return (
                                <div
                                  key={project.slug}
                                  className="my-4 row align-items-center"
                                >
                                  <div className="col-3">
                                    <a
                                      href={project.path}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={project.image.src}
                                        className="img-fluid rounded-5 mt-3"
                                        alt="project tile"
                                      />
                                    </a>
                                  </div>
                                  <div className="col-9">
                                    {project.sortOrder}.{" "}
                                    <strong>
                                      <a
                                        href={project.path}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {project.name}
                                      </a>
                                    </strong>
                                    <HtmlSnippet
                                      snippet={project.shortDescription}
                                      className=""
                                    />
                                    <ul className="list-unstyled">
                                      {project.hasData && (
                                        <li className="list-inline-item">
                                          <strong>Explore: </strong>
                                          <Link
                                            to={`/data-summary/${project.code}`}
                                          >
                                            data
                                          </Link>
                                        </li>
                                      )}
                                      {project.url !== "#" && (
                                        <li className="list-inline-item">
                                          <strong>Project homepage: </strong>
                                          <a href={project.url}>
                                            {project.url}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default About;
