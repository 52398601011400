import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const Team = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Team`;
  const url = `${process.env.REACT_APP_HOMEPAGE}/team`;
  const { data, isLoading, error } = useAxios(url, [], []);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <div className="row">
              <div className="mx-auto col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8">
                <div className="row">
                  <h1 className="col-12">Team</h1>
                </div>
                <div className="row">
                  <div>
                    {data.map((member, index) => (
                      <div
                        key={member.slug}
                        className={`row align-items-center my-2 py-4
                    ${
                      index === data.length - 1
                        ? ""
                        : "border-1 border-secondary border-bottom"
                    }
                    ${index === 0 ? "mt-0" : ""}
                    `}
                      >
                        <div className="col-3 col-xl-2">
                          {member.image && (
                            <img
                              src={member.image.src}
                              alt={member.name}
                              className="img-fluid rounded-5"
                            />
                          )}
                        </div>
                        <div className="col-9 col-xl-10">
                          <HtmlSnippet snippet={member.description} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Team;
