import { useContext, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import { Context } from "../Store";

function useValidateDate() {
  const [state, dispatch] = useContext(Context);

  const [searchParams] = useSearchParams();

  let dateFrom = searchParams.get("dateFrom")
    ? new Date(searchParams.get("dateFrom"))
    : state.initialDates.dateFrom;
  let dateTo = searchParams.get("dateFrom")
    ? new Date(searchParams.get("dateTo"))
    : state.initialDates.dateTo;

  if (dateFrom < state.initialDates.dateFrom) {
    dateFrom = state.initialDates.dateFrom;
  }
  if (dateTo > state.initialDates.dateTo) {
    dateTo = state.initialDates.dateTo;
  }

  if (dateFrom > dateTo) {
    dateFrom = dateTo;
  }
  // console.log('test  from', initialValue.dateFrom, state.initialDates.dateFrom);

  // let iv = Object.fromEntries(searchParams);

  let initialValue = {
    ...Object.fromEntries(searchParams),
    ...{ dateFrom: dateFrom, dateTo: dateTo },
  };

  initialValue.dateFrom = initialValue.dateFrom
    ? new Date(initialValue.dateFrom)
    : state.initialDates.dateFrom;

  if (initialValue.dateFrom.toString() === "Invalid Date") {
    initialValue.dateFrom = state.initialDates.dateFrom;
  }
  if (
    initialValue.dateFrom < state.initialDates.dateFrom ||
    initialValue.dateFrom > state.initialDates.dateTo
  ) {
    initialValue.dateFrom = state.initialDates.dateFrom;
  }
  // console.log('test  to', initialValue.dateTo, state.initialDates.dateTo);

  initialValue.dateTo = initialValue.dateTo
    ? new Date(initialValue.dateTo)
    : state.initialDates.dateTo;

  // console.log('test  to', initialValue.dateTo, state.initialDates.dateTo);

  if (initialValue.dateTo.toString() === "Invalid Date") {
    initialValue.dateTo = state.initialDates.dateTo;
  }
  if (
    initialValue.dateTo > state.initialDates.dateTo ||
    initialValue.dateTo < state.initialDates.dateFrom ||
    initialValue.dateTo < initialValue.dateFrom
  ) {
    initialValue.dateTo = state.initialDates.dateTo;
  }

  return initialValue;
}

export default useValidateDate;
