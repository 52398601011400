import { useEffect, useState } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchAll = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let qs = queryString.parse(location.search);
  const initialKeyword = qs.keyword ?? '';
  const [keyword, setKeyword] = useState(initialKeyword);

  const handleChange = event => {
    setKeyword(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    navigate({
      pathname: '/search',
      search: `keyword=${keyword}`,
    });
  };
  useEffect(() => {
    if (location.pathname !== '/search') {
      setKeyword('');
    } else {
      let qs = queryString.parse(location.search);
      const initialKeyword = qs.keyword ?? '';
      setKeyword(initialKeyword);
    }
  }, [location.pathname, location.search]);

  return (
    <form onSubmit={handleSubmit} className="d-flex">
      <div className="input-group">
        <input
          name="keyword"
          type="text"
          className="form-control"
          placeholder="Search all records…"
          aria-label="Search all records by keyword"
          aria-describedby="basic-addon2"
          value={keyword}
          onChange={handleChange}
        />
        <button type="submit" className="btn btn-primary">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </form>
  );
};

export default SearchAll;
