import { Link } from "react-router-dom";

import HomeProjects from "./home/HomeProjects";
import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const Homepage = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Home`;

  const url = `${process.env.REACT_APP_HOMEPAGE}/page/home`;
  const { data, isLoading, error } = useAxios(url, [], []);

  const chUrl = "https://www.cinemahistories.org/";

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {process.env.REACT_APP_PROJECT_CODE === "ch" ? (
                <>
                  <div className="col-12 text-center fs-4">
                    <div className=""></div>
                    {data.description && (
                      <div className="row">
                        <HtmlSnippet snippet={data.description} className="" />
                      </div>
                    )}
                    <HomeProjects />
                    <div className="mt-5">
                      <Link
                        to="/screening"
                        className="btn btn-secondary m-2 text-uppercase"
                      >
                        Search
                      </Link>
                      <Link
                        to="/about"
                        className="btn btn-secondary m-2 text-uppercase"
                      >
                        Explore
                      </Link>
                      <Link
                        to="/upload"
                        className="btn btn-secondary m-2 text-uppercase"
                      >
                        Contribute
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="full-page row ">
                    <div className="col-6 h-100 d-flex align-items-center">
                      {/* <img
                        className="img-fluid mx-auto border rounded-5 p-3"
                        src={images[process.env.REACT_APP_PROJECT_CODE]}
                        alt="Scala Lichtspiele Sudenburg"
                        style={{ maxHeight: "100%" }}
                      /> */}
                      {data.image && (
                        <img
                          className="img-fluid mx-auto rounded-5"
                          style={{ maxHeight: "100%" }}
                          src={data.image.src}
                          alt="project tile"
                        />
                      )}
                    </div>
                    <div
                      className="col-6 overflow-y-scroll overflow-x-none text-start d-flex align-items-center"
                      style={{ height: "100%" }}
                    >
                      {data.description && (
                        <div className="row fs-5">
                          <HtmlSnippet
                            snippet={data.description}
                            className=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Homepage;
