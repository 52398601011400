import { timeFormat } from "d3-time-format";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  Line,
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const CityActivity = ({ data }) => {
  const dateFormatString = "%d %B %Y";
  const TiltedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    const formatTime = timeFormat(dateFormatString);
    const formattedTick = formatTime(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {formattedTick}
        </text>
      </g>
    );
  };

  const renderTooltip = (props) => {
    const formatTime = timeFormat("%A %d %B %Y");
    const { active, payload } = props;
    if (active) {
      const currData = payload && payload.length ? payload[0].payload : null;
      return (
        <div
          className=""
          style={{
            backgroundColor: "white",
            border: "1px black solid",
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          {currData && <h6>w/c {formatTime(new Date(currData.date))}</h6>}

          <ul className={"list-unstyled"}>
            {payload.map((value, index) => {
              return (
                <li key={index} style={{ color: value.stroke }}>
                  {value.dataKey}: {value.value}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 100,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          scale="time"
          domain={["auto", "auto"]}
          type="number"
          tickFormatter={timeFormat(dateFormatString)}
          tick={TiltedAxisTick}
        />
        <YAxis
          label={{
            value: "Weekly Screenings",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickLine={false}
          axisLine={false}
          tick={false}
        />
        <Tooltip
          labelFormatter={timeFormat(dateFormatString)}
          content={renderTooltip}
        />
        <Legend verticalAlign="top" height={16} />
        <Line type="monotone" dataKey="Cinema" dot={false} stroke="#ff0000" />
        <Line
          type="monotone"
          dataKey="Cinema - Theatre"
          dot={false}
          stroke="#00ad37"
        />
        <Line
          type="monotone"
          dataKey="Open Air Cinema"
          dot={false}
          stroke="#00a6ff"
        />

        {/* <Bar
          yAxisId="right"
          dataKey="Key Date"
          barSize={1}
          fill="#000000"
          opacity={0.7}
        /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

CityActivity.propTypes = {
  data: PropTypes.array,
};

export default CityActivity;
