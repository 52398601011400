import { Link } from "react-router-dom";

import CinemaImages from "./CinemaImages";
import CinemaRecordAggregation from "./CinemaRecordAggregation";
import CinemaRecordSummaryAggregation from "./CinemaRecordSummaryAggregation";
import CinemaSummary from "./CinemaSummary";
import CinemaContainer from "../../map/Container/CinemaContainer";
import ScreeningAggregationChart from "../results/show/chart/ScreeningAggregationChart";
import ScreeningByCityChart from "../results/show/chart/ScreeningsByCityChart";
import MostPopular from "../results/show/MostPopular";

const CinemaDetail = ({
  cinema,
  cinemaAggregations,
  screenings,
  linkAggregations,
  aggregations,
  details,
}) => {
  return (
    <>
      <div className="row">
        <h1 className="col">
          {cinema.name},{" "}
          <Link to={`/city/${cinema.cityTagSlug}`}>{cinema.cityTag}</Link>
        </h1>
      </div>
      <MostPopular
        linkAggregations={linkAggregations}
        aggregations={aggregations}
      />
      <ScreeningByCityChart screenings={screenings} />

      <div className="row">
        <div className="col-lg-6">
          {/* <CinemaSummary cinema={cinema} details={details} /> */}
        </div>
        <div className="col-lg-6">
          {/* <CinemaContainer cinema={cinema} /> */}
        </div>
      </div>
      <div>
        {
          // cinema.images.length > 0 && (
          // <>
          //   <div className="row mt-4">
          //     <h2 className="col">Gallery</h2>
          //   </div>
          //   {/* <CinemaImages cinema={cinema} /> */}
          // </>
          // )
        }
      </div>

      <div className="row">
        {linkAggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
        {aggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <h3>Screening Summary</h3> */}
          {/* <CinemaRecordAggregation aggs={cinemaAggregations} />

          <CinemaRecordSummaryAggregation
            bucketName={"filmCoProductionCountry"}
            bucketTitle={"Combined Production Countries"}
            aggregation={cinemaAggregations.time_period.buckets}
          />
          <CinemaRecordSummaryAggregation
            bucketName={"filmProductionCountry"}
            bucketTitle={"Involved Production Country"}
            aggregation={cinemaAggregations.time_period.buckets}
          />
          <CinemaRecordSummaryAggregation
            bucketName={"filmYear"}
            bucketTitle={"Film Year"}
            aggregation={cinemaAggregations.time_period.buckets}
          />
          <CinemaRecordSummaryAggregation
            bucketName={"filmGenre"}
            bucketTitle={"Film Genre"}
            aggregation={cinemaAggregations.time_period.buckets}
          /> */}
        </div>
      </div>
      {/* TODO add these */}
      {/* <div className="row">
        <div className="col-md-12">
          <h3>Price Proxy</h3>
          <PriceProxyTable
            n={n}
            ppYears={priceProxy.years}
            ppFilms={priceProxy.films}
            ppCinemas={priceProxy.cinemas}
            ppCinemaCounts={priceProxy.cinemaCounts}
          />
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-md-12">
          <h3>Number of top 20 films shown</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>1951</th>
                <th>1952</th>
                <th>1953</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{countTop20_1951}</td>
                <td>{countTop20_1952}</td>
                <td>{countTop20_1953}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          {/* <EntityInterview transcriptLines={transcriptLines} /> */}
        </div>
      </div>
    </>
  );
};
export default CinemaDetail;
