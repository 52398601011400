import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import Aggregation from "./Aggregation";

const AggregationList = ({ aggregations }) => {
  const location = useLocation();

  return (
    <div className="mb-2">
      <div className="d-flex flex-row">
        <div
          className="map-heading map-collapsible-heading me-2"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-filters"
          aria-expanded="false"
          aria-controls="collapse-filters"
        >
          <span className="ms-2">Filter Cinemas</span>
        </div>
        <div className="ms-auto">
          <Link
            className="btn btn-link btn-sm me-1"
            to={{
              pathname: location.pathname,
            }}
          >
            Reset All
          </Link>
        </div>
      </div>

      <div id="collapse-filters" className="collapse">
        <div className="row">
          <div className="col">
            <ul className="list-unstyled ms-4">
              {aggregations.map((aggregation) => (
                <li key={aggregation.name} className="">
                  <Aggregation aggregation={aggregation} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

AggregationList.propTypes = {
  aggregations: PropTypes.array,
  include: PropTypes.array,
  filterTitle: PropTypes.string,
};

export default AggregationList;
