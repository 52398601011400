import { useContext } from "react";

import { useParams } from "react-router-dom";

import TopFilmList from "./topFilm/TopFilmList";
import CityChoices from "../navigation/CityChoices";
import NChoices from "../navigation/NChoices";
import PeriodChoices from "../navigation/PeriodChoices";
import ProjectChoices from "../navigation/ProjectChoices";
import SearchTopHeader from "../search/SearchTopHeader";
import { Context } from "../Store";

const TopFilm = () => {
  const { city } = useParams();
  const [state] = useContext(Context);

  const currentCity = state.navigationCities.find((c) => c.key === city);
  const cityName = currentCity !== undefined ? currentCity.name : "";
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Top Films: ${cityName}`;

  return (
    <>
      <div className="row mb-4">
        <div className="row mb-4">
          {cityName !== "" && (
            <h1 className="col-12 text-center">Top Films for {cityName}</h1>
          )}
          {state.navigationCities.length > 0 && cityName === "" && (
            <h1 className="col-12 text-center">
              Top Films for- Error unknown city!
            </h1>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-start">
          <PeriodChoices />
          {process.env.REACT_APP_PROJECT_CODE === "ch" && <ProjectChoices />}
          <NChoices />
          <CityChoices />
          <SearchTopHeader />
        </div>
      </div>
      <div className="">{cityName !== "" && <TopFilmList />}</div>
    </>
  );
};

export default TopFilm;
