import { useEffect } from "react";

import { Outlet, useParams } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const CinemaView = () => {
  const { slug } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Cinemas`;

  const apiPath = `${process.env.REACT_APP_DATA_URL}/cinema/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {data.item && (
                <Outlet
                  context={[data.item, data.data, data.cinema_aggregations]}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CinemaView;
