import { useLayoutEffect } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  //ignore map pan/zoom
  searchParams.delete("lat");
  searchParams.delete("lng");
  searchParams.delete("zoom");

  const search = searchParams.toString();

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname, search]);

  return null;
}

export default ScrollToTop;
