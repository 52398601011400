import { createContext, useReducer } from "react";

import Reducer from "./Reducer";

const initialState = {
  projects: [],
  cities: [],
  cinemas: [],
  navigationCities: [],
  navigationProjects: [],
  timePeriods: [],
  nResults: [],
  // ranks: [],
  // navigationRanks: [],
  // seatingRanges: [],
  initialDates: {
    dateFrom: new Date("1900-01-01"),
    dateTo: new Date("1999-12-31"),
  },
  markerTypes: [],
  error: null,
  isLoading: true,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
