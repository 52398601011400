export const formatISODate = date => {
  return date.toISOString().split('T')[0];
};

export const formatSeconds = seconds => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const formatTimePeriod = (
  date,
  period,
  fromInitial = new Date(),
  toInitial = new Date(),
) => {
  let fullDate = new Date(date);
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  let displayDate = '';
  let fullEndDate = fullDate;
  switch (period) {
    case '1w':
      options = { year: 'numeric', month: 'long', day: 'numeric' };
      fullEndDate = new Date(fullDate);
      fullEndDate.setDate(fullDate.getDate() + 6);
      displayDate =
        fullDate.toLocaleDateString('en-GB', options) +
        ' - ' +
        fullEndDate.toLocaleDateString('en-GB', options);
      break;
    case '1M':
      options = { year: 'numeric', month: 'long' };
      displayDate = fullDate.toLocaleDateString('en-GB', options);
      fullEndDate = new Date(new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 1) - 1);
      break;
    case '1q':
      options = { year: 'numeric', month: 'long' };
      fullEndDate = new Date(new Date(fullDate.getFullYear(), fullDate.getMonth() + 3, 1) - 1);
      displayDate =
        fullDate.toLocaleDateString('en-GB', options) +
        ' - ' +
        fullEndDate.toLocaleDateString('en-GB', options);
      break;
    case '1y':
      options = { year: 'numeric' };
      displayDate = fullDate.toLocaleDateString('en-GB', options);
      fullEndDate = new Date(fullDate.getFullYear(), 11, 31);
      break;
    case 'all':
      options = { year: 'numeric', month: 'long', day: 'numeric' };
      fullDate = fromInitial;
      fullEndDate = toInitial;
      displayDate =
        fullDate.toLocaleDateString('en-GB', options) +
        ' - ' +
        fullEndDate.toLocaleDateString('en-GB', options);
      break;
    default:
      options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      displayDate = fullDate.toLocaleDateString('en-GB', options);
      break;
  }

  const periodFrom = formatISODate(fullDate);
  const periodTo = formatISODate(fullEndDate);

  return { displayDate, periodFrom, periodTo };
};
