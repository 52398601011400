import { Link } from "react-router-dom";

import chLogo from "../images/ch-logo.png";

const Footer = () => {
  return (
    <footer className="page-footer py-0">
      <div
        className="text-center"
        style={{
          height: "18px",
        }}
      >
        {/* <a href="https://www.cinemahistories.org/" className="p-0 m-y0 mx-2">
          <img
            src={chLogo}
            alt="Cinema Histories Logo"
            style={{
              width: "110px",
              height: "18px",
              objectFit: "cover",
            }}
          />
        </a> */}
        {/* <a className="link-light" href="https://www.dhi.ac.uk">
          © DHI
        </a> */}
        <Link className="link-light" to="/copyright-and-permissions">
          Copyright and Permissions
        </Link>
      </div>
    </footer>
  );
};
export default Footer;
