import PropTypes from "prop-types";
import { Link, useSearchParams, useParams } from "react-router-dom";

const ActiveFilm = ({ films }) => {
  const { n, period, city } = useParams();

  const [searchParams] = useSearchParams();
  const active = searchParams.get("active")
    ? parseInt(searchParams.get("active"))
    : 1;

  const buttons = films.map((film, index) => {
    const currentActive = index + 1;
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("active", currentActive);
    return (
      <Link
        className={
          "btn btn-sm " +
          (active === currentActive ? "btn-danger" : "btn-outline-danger")
        }
        key={index}
        to={{
          pathname: `/top/film/${city}/${period}/${n}/cinema`,
          search: newSearchParams.toString(),
        }}
        replace={true}
        title={film.film_title.buckets[0].key}
      >
        {currentActive}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3">{buttons}</div>;
};

ActiveFilm.propTypes = {
  films: PropTypes.array,
};

export default ActiveFilm;
