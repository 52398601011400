import { useRef, useEffect } from "react";

import { FeatureGroup, useMap } from "react-leaflet";
// import MarkerClusterGroup from 'react-leaflet-cluster';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const MarkerLayer = ({ markers, isLoading }) => {
  const map = useMap();
  const featureGroupRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.delete("lat");
  searchParams.delete("lng");
  searchParams.delete("zoom");

  useEffect(() => {
    if (!map) {
      return;
    }
    const bounds = featureGroupRef.current.getBounds();
    if (bounds.isValid()) {
      //   map.fitBounds(bounds);
      map.fitBounds(bounds, { padding: [100, 100] });
    }
  }, [map, isLoading]);

  return (
    <FeatureGroup ref={featureGroupRef}>
      {/* <MarkerClusterGroup chunkedLoading></MarkerClusterGroup> */}
      {markers}
    </FeatureGroup>
  );
};
export default MarkerLayer;
