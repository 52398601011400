import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScreeningAggregationChart from "../results/show/chart/ScreeningAggregationChart";
import ScreeningByCityChart from "../results/show/chart/ScreeningsByCityChart";
import MostPopular from "../results/show/MostPopular";

const FilmDetail = ({ film, screenings, linkAggregations, aggregations }) => {
  console.log(film);
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="d-inline">{film.title}</h1>
          {film.imdbId && (
            <a
              className="ms-2"
              href={`https://www.imdb.com/title/${film.imdbId}`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> IMDb
            </a>
          )}
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-12">{film.city.join(', ')}</div>{' '}
      </div> */}
      <MostPopular
        linkAggregations={linkAggregations}
        aggregations={aggregations}
      />

      <ScreeningByCityChart screenings={screenings} />

      <div className="row">
        {linkAggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
        {aggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
      </div>
    </>
  );
};
export default FilmDetail;
