import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import ScreeningAggregationChart from "../results/show/chart/ScreeningAggregationChart";
import ScreeningByCityChart from "../results/show/chart/ScreeningsByCityChart";
import MostPopular from "../results/show/MostPopular";

const CompanyDetail = ({
  company,
  screenings,
  linkAggregations,
  aggregations,
}) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="d-inline">
            {company.name} (
            {company.type.map((type) => (
              <Link
                key={type}
                className="mx-2"
                to={`/company?filter_type_raw[]=${type}`}
              >
                {type}
              </Link>
            ))}
            )
          </h1>
          {company.imdbId && (
            <>
              {/* which is the best url for company on imdb? */}
              {/* <a
                className="ms-2"
                href={`https://www.imdb.com/search/title/?companies=${company.imdbId}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} /> IMDb
              </a> */}
              <a
                className="ms-2"
                href={`https://www.imdb.com/company/${company.imdbId}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} /> IMDb
              </a>
            </>
          )}
        </div>
      </div>

      <MostPopular
        linkAggregations={linkAggregations}
        aggregations={aggregations}
      />

      <ScreeningByCityChart screenings={screenings} />

      <div className="row">
        {linkAggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
        {aggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
      </div>
    </>
  );
};
export default CompanyDetail;
