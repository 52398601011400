import { useState, useContext } from "react";

import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Pane,
  Marker,
  Popup,
} from "react-leaflet";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { Context } from "../../Store";
import MarkerLayer from "../Layer/MarkerLayer";
import AggregationList from "../Navigation/AggregationList";
import CityNavigation from "../Navigation/CityNavigation";
import ZoomDrag from "../Navigation/ZoomDrag";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const BaseContainer = ({
  markers,
  center = [50, 10],
  initialZoom = 4,
  showCityNav = true,
  showMarkerNav = false,
  showCities = true,
  showAggregations = false,
  isLoading = false,
  aggregations = [],
  mapHeight = "calc(100vh - 180px)",
}) => {
  const [searchParams] = useSearchParams();

  const [state] = useContext(Context);

  const tms = false;
  const minZoom = 4;
  const maxZoom = 18;
  const [map, setMap] = useState(null);
  const [opacity, setOpacity] = useState(0.8);

  const isLatitude = (lat) => {
    return isFinite(lat) && Math.abs(lat) <= 90;
  };

  const isLongitude = (lng) => {
    return isFinite(lng) && Math.abs(lng) <= 180;
  };

  if (searchParams.get("lat") && searchParams.get("lng")) {
    const lat = parseFloat(searchParams.get("lat"));
    const lng = parseFloat(searchParams.get("lng"));
    if (isLatitude(lat) && isLongitude(lng)) {
      center = [lat, lng];
    }
  }

  if (
    searchParams.get("zoom") &&
    parseInt(searchParams.get("zoom"), 10) >= minZoom &&
    parseInt(searchParams.get("zoom"), 10) <= maxZoom
  ) {
    initialZoom = parseInt(searchParams.get("zoom"), 10);
  }

  const goToCity = (citySlug) => {
    const center = state.cities.find((city) => city.slug === citySlug).center;
    // const zoom = state.cities.find((city) => city.id === cityId).defaultZoom;
    const zoom = 12;

    map && map.setView(center, zoom);
  };

  const cityIcon = new L.Icon.Default({ className: "city-marker" });

  const cityMarkers = state.cities.map((city) => {
    const position = [city.center.lat, city.center.lon];
    const marker = (
      <Marker key={city.slug} position={position} icon={cityIcon}>
        <Popup>
          <Link to={`/city/${city.slug}`}>{city.name}</Link>
        </Popup>
      </Marker>
    );
    return marker;
  });

  return (
    // <div className="map-wrapper">
    <div className="d-flex flex-row">
      {(showCityNav || showAggregations) && (
        <div
          className="map-filters pe-4"
          style={{
            height: "calc(100vh - 180px)",
            width: "300px",
            overflow: "scroll",
          }}
        >
          <div style={{}}>
            {showAggregations && (
              <>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <AggregationList aggregations={aggregations} />
                )}
              </>
            )}
            {showCityNav && map && (
              <div
                className="collapse show"
                id="mapOptions"
                style={{ height: "100vh", overflow: "scroll" }}
              >
                <CityNavigation
                  map={map}
                  cities={showCityNav ? state.cities : {}}
                  onClick={goToCity}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="map-map flex-fill" style={{ height: mapHeight }}>
        <MapContainer
          center={center}
          zoom={initialZoom}
          scrollWheelZoom={true}
          whenReady={(e) => setMap(e.target)}
          style={{ height: "100%" }}
          zoomControl={false}
        >
          <ZoomControl position={"bottomright"} />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            tms={tms}
            minZoom={minZoom}
            maxZoom={maxZoom}
            detectRetina={true}
          />
          <Pane name="markers" style={{ zIndex: "10000" }}>
            <MarkerLayer markers={markers} isLoading={isLoading} />
          </Pane>
          {showCities && (
            <Pane name="city-markers" style={{ zIndex: "10001" }}>
              <MarkerLayer markers={cityMarkers} isLoading={isLoading} />
            </Pane>
          )}
          <ZoomDrag cities={state.cities} />
        </MapContainer>
      </div>
    </div>
  );
};

export default BaseContainer;
