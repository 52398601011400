import { useState, useEffect } from "react";

import axios from "axios";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";

import ItemRow from "./results/ItemRow";
import TableHeading from "./results/TableHeading";
import Loading from "../layout/Loading";

const Search = () => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Search`;
  }, []);

  const location = useLocation();

  const [projects, setProjects] = useState({ items: [], columns: [] });
  const [cities, setCities] = useState({ items: [], columns: [] });
  const [cinemas, setCinemas] = useState({ items: [], columns: [] });
  const [films, setFilms] = useState({ items: [], columns: [] });
  const [screenings, setScreenings] = useState({ items: [], columns: [] });
  const [people, setPeople] = useState({ items: [], columns: [] });
  const [companies, setCompanies] = useState({ items: [], columns: [] });

  const [isLoading, setIsLoading] = useState(true);
  const apiPath = `${process.env.REACT_APP_DATA_URL}/search`;

  const qs = queryString.parse(location.search);
  const keyword = qs.keyword ?? "";

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${apiPath}${location.search}`, { withCredentials: true })
      .then((res) => {
        console.log(res.data);
        const projects = res.data.results.project;
        const cities = res.data.results.city;
        const cinemas = res.data.results.cinema;
        const films = res.data.results.film;
        const screenings = res.data.results.screening;
        const people = res.data.results.person;
        const companies = res.data.results.company;
        setProjects(projects);
        setCities(cities);
        setCinemas(cinemas);
        setFilms(films);
        setScreenings(screenings);
        setPeople(people);
        setCompanies(companies);
        setIsLoading(false);
      });

    // document.getElementById('main').scroll(0, 0);
  }, [location.search, apiPath]);

  return (
    <div>
      <div className="row">
        <div className="col">
          <h1 className="mb-3 pb-3">
            Search Results{keyword && ` for "${keyword}"`}
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {projects.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Projects</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={projects.columns}
                        showImage={false}
                        showProject={false}
                        sort={false}
                      />
                      <tbody>
                        {projects.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={projects.columns}
                            item={item}
                            showImage={false}
                            showProject={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/project",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more projects
                    </Link>
                  </div>
                </div>
              )}
              {cities.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Cities</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={cities.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {cities.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={cities.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/city",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more cities
                    </Link>
                  </div>
                </div>
              )}
              {cinemas.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Cinemas</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={cinemas.columns}
                        showImage={true}
                        sort={false}
                      />
                      <tbody>
                        {cinemas.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={cinemas.columns}
                            item={item}
                            showImage={true}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/cinema",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more cinemas
                    </Link>
                  </div>
                </div>
              )}
              {films.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Films</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={films.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {films.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={films.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/film",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more films
                    </Link>
                  </div>
                </div>
              )}
              {screenings.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Screenings</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={screenings.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {screenings.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={screenings.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/screening",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more screenings
                    </Link>
                  </div>
                </div>
              )}
              {/* {people.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">People</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={people.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {people.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={people.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/person",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more people
                    </Link>
                  </div>
                </div>
              )}
              {companies.items.length > 0 && (
                <div className="mb-5 pb-5 border-bottom border-5 border-primary">
                  <h2 className="col">Companies</h2>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover results">
                      <TableHeading
                        columns={companies.columns}
                        showImage={false}
                        sort={false}
                      />
                      <tbody>
                        {companies.items.map((item) => (
                          <ItemRow
                            key={item.slug}
                            columns={companies.columns}
                            item={item}
                            showImage={false}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/company",
                        search: `keyword=${keyword}`,
                      }}
                      className="float-end"
                    >
                      View more companies
                    </Link>
                  </div>
                </div>
              )}  */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
