import BaseContainer from "./BaseContainer";
import useResults from "../../hooks/useResults";
import SearchMarkers from "../Marker/SearchMarkers";

const EcaContainer = () => {
  const apiPath = `${process.env.REACT_APP_DATA_URL}/map/cinema-search`;

  const { items, aggregations, isLoading, error } = useResults(apiPath);

  return (
    <BaseContainer
      showMarkerNav={true}
      showAggregations={false}
      isLoading={isLoading}
      markers={<SearchMarkers cinemas={items} />}
    />
  );
};

export default EcaContainer;
