import { Link } from "react-router-dom";

const ScreeningDetail = ({
  screening,
  screenings,
  linkAggregations,
  aggregations,
}) => {
  return (
    <>
      {/* <div className="row">
        <div className="col-md-12">
          {' '}
          Screenings Summaries by
          <ul className="list-unstlyed list-inline">
            <li className="list-inline-item">City</li>
            <li className="list-inline-item">Year</li>
          </ul>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <h1 className="">
            {/* <Link to={`/film/${activeScreening.overview.filmId.value}`}>
              {activeScreening.overview.title.value}
            </Link> */}
            <Link to={`/film/${screening.filmTagSlug}`}>
              {screening.filmTag}
            </Link>
            ,{" "}
            <small>
              <Link to={`/cinema/${screening.cinemaTagSlug}`}>
                {screening.cinemaTag}
              </Link>{" "}
              (
              <Link to={`/city/${screening.cityTagSlug}`}>
                {screening.cityTag}
              </Link>
              ) {/* TODO add city to screening add */}
              {/* ,{" "}
              <Link
                to={`/screening/?filter_cityDisplayName_raw[]=${screening.cinemaTagSlug}`}
              >
                {screening.cinemaTag}
              </Link> */}{" "}
              – {new Date(screening.date).toLocaleDateString()}
            </small>{" "}
            [
            <Link to={`/data-summary/${screening.projectCode}`}>
              {screening.projectName}
            </Link>
            ]
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {/* {activeScreening.summary.map((summary, index) => (
            <div key={index}>
              <strong>{summary.name}</strong> {summary.value}
            </div>
          ))} */}
        </div>
        <div className="col-md-6">
          {/* <h3>
            All screenings on this day show at{" "}
            <Link to={`/cinema/${cinema.id}`}>{cinema.name}</Link>:
          </h3>
          <ul className="list-unstyled">
            {screenings.map((screening) => (
              <li key={screening.id}>
                <Link to={`/film/${screening.overview.filmId.value}`}>
                  {screening.overview.title.value}
                </Link>
              </li>
            ))}
          </ul> */}
        </div>
      </div>
    </>
  );
};
export default ScreeningDetail;
