import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Link, useParams } from "react-router-dom";

const TopCinemaListItem = ({ cinemas }) => {
  const [searchParams] = useSearchParams();

  const { n, period, city } = useParams();

  const sortScreening = new URLSearchParams(searchParams);
  sortScreening.set("cinemaSort", "screening");
  const sortUniqueTitle = new URLSearchParams(searchParams);
  sortUniqueTitle.set("cinemaSort", "unique_title");
  const sortWeekday = new URLSearchParams(searchParams);
  sortWeekday.set("cinemaSort", "weekday");
  const sortWeekend = new URLSearchParams(searchParams);
  sortWeekend.set("cinemaSort", "weekend");

  const pathname = `/cinema/top/${city}/${period}/${n}`;

  const listItems = cinemas.map((cinema) => {
    const city = cinema.city.buckets[0].key;
    return (
      <tr key={cinema.key} className="">
        <td>
          <Link to={`/cinema/${cinema.key}`}>
            {cinema.cinemaName.buckets[0].key}, {city}
          </Link>
        </td>
        <td>{cinema.doc_count}</td>
        <td>{cinema.unique_title.value}</td>
        <td>{cinema.isWeekend.doc_count}</td>
        <td>{cinema.isWeekday.doc_count}</td>
      </tr>
    );
  });
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Cinema</th>
          <th>
            <Link
              to={{
                pathname: pathname,
                search: sortScreening.toString(),
              }}
            >
              No of screenings
            </Link>
          </th>
          <th>
            <Link
              to={{
                pathname: pathname,
                search: sortUniqueTitle.toString(),
              }}
            >
              No of Unique Titles
            </Link>
          </th>
          <th>
            <Link
              to={{
                pathname: pathname,
                search: sortWeekday.toString(),
              }}
            >
              No of screenings on Weekday
            </Link>
          </th>
          <th>
            <Link
              to={{
                pathname: pathname,
                search: sortWeekend.toString(),
              }}
            >
              No of screenings on Weekend
            </Link>
          </th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </table>
  );
};

TopCinemaListItem.propTypes = {
  cinemas: PropTypes.array,
};

export default TopCinemaListItem;
