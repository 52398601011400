import CinemaRunCinema from "./CinemaRunCinema";
const TopCinemaCinemaRun = ({ film, city }) => {
  return (
    <>
      <CinemaRunCinema cinemas={film.venue.buckets} city={city} />
    </>
  );
};

export default TopCinemaCinemaRun;
