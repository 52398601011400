import CityActivity from "../chart/CityActivity";

const CityActivityList = ({ chartData }) => {
  const charts = chartData.map((city) => {
    return (
      <div key={city.city}>
        <h2>{city.city}</h2>
        <CityActivity data={city.data} />
      </div>
    );
  });

  return charts;
};

export default CityActivityList;
