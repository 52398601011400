import { useEffect } from "react";

import { useParams } from "react-router-dom";

import ScreeningDetail from "./screening/ScreeningDetail";
import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const ScreeningShow = () => {
  const { slug } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - People`;

  const apiPath = `${process.env.REACT_APP_DATA_URL}/screening/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);
  useEffect(() => {
    if (data.screening) {
      document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Screenings: ${data.screening.name}`;
    }
  }, [data]);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {(data.item || data.screening) && (
                <ScreeningDetail
                  // screenings={data.screening}
                  // activeScreening={data.activeScreening}
                  screening={data.item}
                  // date={new Date(data.item.date)}
                  // film={data.item.film}
                  screenings={data.data.screenings}
                  linkAggregations={data.data.linkAggregations}
                  aggregations={data.data.aggregations}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ScreeningShow;
