import { path } from "d3";
import { Link } from "react-router-dom";

const MostPopular = ({ linkAggregations, aggregations }) => {
  return (
    <div className="row">
      <div className="col">
        <strong>Most Popular; </strong>
        {linkAggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <div key={aggregation.key} className="d-inline pe-2">
              <strong>{aggregation.name}:</strong>
              {aggregation.values
                .filter(
                  (value) =>
                    value.screenings ===
                    aggregation.values.reduce(
                      (a, b) => (b.screenings > a ? b.screenings : a),
                      0
                    )
                )
                .map((value) => {
                  return (
                    <span key={value.id}>
                      {/* TODO add a link to search current entity with this value */}
                      <Link
                        to={{
                          pathname: `/${aggregation.name.toLowerCase()}/${
                            value.id
                          }`,
                        }}
                      >
                        {value.name}
                      </Link>
                    </span>
                  );
                })}
            </div>
          ))}
        {aggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <div key={aggregation.key} className="d-inline pe-2">
              <strong>{aggregation.name}:</strong> {aggregation.values[0].name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MostPopular;
