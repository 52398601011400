import { Link } from "react-router-dom";
import { useOutletContext, useParams } from "react-router-dom";

import CinemaImages from "./cinema/CinemaImages";
const CinemaImageShow = () => {
  const { image } = useParams();
  const [cinema] = useOutletContext();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Cinema: ${cinema.name} Images`;

  const selectedImage = cinema.images.find(
    (img) => img.id === parseInt(image, 10)
  );

  return (
    <div className="">
      {cinema && (
        <>
          <div className="row">
            <h1 className="col-3">
              <Link to={`/cinema/${cinema.id}`}>{cinema.name}</Link>: Images
            </h1>
            {selectedImage && (
              <>
                <div className="col-6">
                  <a href={selectedImage.srcFullsize}>
                    <img
                      src={selectedImage.srcLarge}
                      alt=""
                      height="400"
                      className="mx-auto mb-4 d-block img-fluid"
                      style={{
                        maxHeight: "calc( 100vh - 330px)",
                        // maxWidth: '90vw',
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="col-3">
                  <div className="">
                    <div className="">
                      {/* {selectedImage.details.map((detail, index) => (
                        <div key={index} className="me-4">
                          <strong>{detail.name}:</strong> {detail.value}
                        </div>
                      ))} */}
                      {selectedImage.source && (
                        <div className="me-4">
                          <strong>Source:</strong> {selectedImage.source}
                        </div>
                      )}
                      {selectedImage.furtherInformation && (
                        <div className="me-4">
                          <strong>Further Information:</strong>{" "}
                          {selectedImage.furtherInformation}
                        </div>
                      )}
                      {selectedImage.date && (
                        <div className="me-4">
                          <strong>Date:</strong> {selectedImage.date}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="row" style={{ overflowX: "scroll" }}>
            <div className="col-md-12">
              <CinemaImages cinema={cinema} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CinemaImageShow;
