import { useSearchParams } from "react-router-dom";

const SearchSummary = ({ pagination }) => {
  const [searchParams] = useSearchParams();
  const textQuery = searchParams.get("text") ? searchParams.get("text") : "";
  if (!pagination.totalCount) {
    return null;
  }
  return (
    <>
      {pagination.totalCount === 0 && (
        <p>
          <strong>No records</strong> matched this query.
        </p>
      )}
      {pagination.totalCount > 0 && textQuery !== "" && (
        <p>
          <strong>
            {pagination.totalCount.toLocaleString()} record
            {pagination.totalCount !== 1 && <>s</>}
          </strong>{" "}
          matched this query.
        </p>
      )}
      {pagination.totalCount > 0 && textQuery === "" && (
        <p>
          <strong>{pagination.totalCount.toLocaleString()}</strong> total record
          {pagination.totalCount !== 1 && <>s</>}.
        </p>
      )}
    </>
  );
};
export default SearchSummary;
