import { useSearchParams, useParams } from "react-router-dom";

import TopCinemaPeriods from "./TopCinemaPeriods";
import useAxios from "../../hooks/useAxios";
import Error from "../../layout/Error";
import Loading from "../../layout/Loading";

const TopCinemaList = () => {
  const { project, city, period, n } = useParams();

  const [searchParams] = useSearchParams();

  const apiPath = `${process.env.REACT_APP_DATA_URL}/top/cinema/${project}/city/${city}/${period}/${n}`;
  const { data, isLoading, error } = useAxios(
    apiPath,
    [],
    [apiPath, searchParams.toString()]
  );

  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <TopCinemaPeriods
                periods={data.aggregations.time_period.buckets}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopCinemaList;
