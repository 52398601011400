import { useContext } from "react";

import { timeFormat } from "d3-time-format";
import {
  ResponsiveContainer,
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { Context } from "../../../../Store";
import COLORS from "../../../../utils/colors";
const ScreeningByCityChart = ({ screenings }) => {
  const [state] = useContext(Context);

  const dateFormatString = "%e %B %Y";

  const TiltedDateAxisTick = (props) => {
    const { x, y, payload } = props;
    const formatTime = timeFormat(dateFormatString);
    const formattedTick = formatTime(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {formattedTick}
        </text>
      </g>
    );
  };

  const renderTooltip = (props) => {
    const formatTime = timeFormat("%A %d %B %Y");
    const { active, payload } = props;
    if (active) {
      const currData = payload && payload.length ? payload[0].payload : null;
      return (
        <div
          className=""
          style={{
            backgroundColor: "white",
            border: "1px black solid",
            borderRadius: "20px",
            padding: "10px",
            width: "400px",
          }}
        >
          {currData && (
            <>
              <h6>Month Starting: {formatTime(new Date(currData.date))}</h6>

              <ul className={"list-unstyled"}>
                {payload.map((value, index) => {
                  return value.dataKey === "Key Date" ? (
                    <li key={index} style={{ color: "black" }}>
                      Key Dates: {currData.keyDate}
                    </li>
                  ) : (
                    <li key={index} style={{ color: value.fill }}>
                      {value.dataKey}: {value.value}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="row">
        <h2 className="col mt-4 mb-2">Screenings by City</h2>
      </div>
      <div className="row">
        <div className="col">
          <ResponsiveContainer width={"100%"} height={500}>
            <ComposedChart
              data={screenings}
              margin={{
                top: 20,
                right: 30,
                left: 10,
                bottom: 100,
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis
                dataKey="date"
                scale="time"
                domain={["auto", "auto"]}
                type="number"
                interval={4}
                tickFormatter={timeFormat(dateFormatString)}
                tick={TiltedDateAxisTick}
                padding={{ left: 10, right: 10 }}
              />
              <YAxis
                label={{
                  value: "Monthly Screenings",
                  offset: 20,
                  angle: -90,
                  position: "insideBottomLeft",
                }}
              />
              <Tooltip
                labelFormatter={timeFormat(dateFormatString)}
                content={renderTooltip}
              />
              <Legend verticalAlign="top" height={120} />
              {state.cities.map((city, index) => (
                <Bar
                  key={city.slug}
                  stackId="a"
                  dataKey={city.name}
                  // fill={city.color}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default ScreeningByCityChart;
