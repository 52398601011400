const Error = ({ error }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-center mt-4 pt-4 mb-4 pb-4">
          {error.message}
        </div>
      </div>
    </div>
  );
};

export default Error;
