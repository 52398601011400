import { Collapse } from "bootstrap";
import { Link, NavLink } from "react-router-dom";

import SearchAll from "./SearchAll";
import camLogo from "../images/cam-logo.svg";
import cbLogo from "../images/cb-logo.png";
import ccwLogo from "../images/ccw-logo.svg";
import chLogo from "../images/ch-logo.png";
import chacLogo from "../images/chac-logo.svg";
import dfpLogo from "../images/dfp-logo.svg";
import ecaLogo from "../images/eca-logo.svg";
import hflaLogo from "../images/hfla-logo.svg";
import hmsLogo from "../images/hms-logo.svg";
import lfalLogo from "../images/lfal-logo.svg";
import scelLogo from "../images/scel-logo.svg";
import scmLogo from "../images/scm-logo.svg";

const Navbar = ({ hasData }) => {
  let logo = chLogo;
  switch (process.env.REACT_APP_PROJECT_CODE) {
    case "cam":
      logo = camLogo;
      break;
    case "cb":
      logo = cbLogo;
      break;
    case "ccw":
      logo = ccwLogo;
      break;
    case "chac":
      logo = chacLogo;
      break;
    case "dfp":
      logo = dfpLogo;
      break;
    case "eca":
      logo = ecaLogo;
      break;
    case "hfla":
      logo = hflaLogo;
      break;
    case "hms":
      logo = hmsLogo;
      break;
    case "lfal":
      logo = lfalLogo;
      break;
    case "scel":
      logo = scelLogo;
      break;
    case "scm":
      logo = scmLogo;
      break;
    default:
      logo = chLogo;
      break;
  }
  return (
    <>
      <nav className="navbar navbar-light bg-white navbar-expand-md my-0 pt-2 pb-1 ps-0 pe-3 align-items-center justify-content-md-between justify-content-around">
        <div className="mx-2 d-flex align-items-center">
          <NavLink to="/" className="nav-link">
            <img
              src={logo}
              height="50"
              alt={process.env.REACT_APP_PROJECT_NAME}
              className="py-1 px-2"
            />
          </NavLink>
          {process.env.REACT_APP_PROJECT_CODE !== "ch" && (
            <a
              href="https://www.cinemahistories.org/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <div className="d-flex align-items-center">
                <div className="">
                  <div className="ms-2 me-2">Part of:</div>
                </div>
                <div className="">
                  <img
                    src={chLogo}
                    height="30"
                    alt={process.env.REACT_APP_PROJECT_NAME}
                  />
                </div>
              </div>
            </a>
          )}
        </div>
        {hasData && (
          <div className="navbar-expand-sm">
            <div
              className="navbar-collapse collapse  mt-2 mt-md-0 eca-navbar-responsive"
              id="eca-navbar-search"
            >
              <SearchAll />
            </div>
          </div>
        )}

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".eca-navbar-responsive"
          aria-controls="ecaNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
      <nav className="navbar navbar-light bg-white navbar-expand-md pt-1 pb-3 ps-3 pe-3 d-flex align-items-center border-primary border-2 border-bottom">
        <div
          className="navbar-collapse collapse flex-wrap justify-content-between eca-navbar-responsive"
          id="eca-navbar"
        >
          <ul className="navbar-nav bd-navbar-nav eca-menu mt-0 py-md-0 ">
            <li className="dropdown">
              <NavLink to="/about" className="nav-link eca-about">
                About
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle eca-about-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <NavLink to="/team" className="dropdown-item">
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/copyright-and-permissions"
                    className="dropdown-item"
                  >
                    Copyright and Permissions
                  </NavLink>
                </li>
                {process.env.REACT_APP_PROJECT_CODE === "ch" && (
                  <li>
                    <NavLink to="/faq" className="dropdown-item">
                      FAQ
                    </NavLink>
                  </li>
                )}
                {/* <li>
                  <NavLink
                    to="/participating-project"
                    className="dropdown-item"
                  >
                    Participating Projects
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/upload" className="dropdown-item">
                    Upload Your Data
                  </NavLink>
                </li> */}
              </ul>
              {/* </div> */}
            </li>
            {hasData && (
              <>
                <li className="nav-item col-6 col-md-auto">
                  <NavLink to="/data-summary" className="nav-link">
                    Data Summary
                  </NavLink>
                </li>

                <li className="nav-item col-6 col-md-auto">
                  <NavLink to="/city" className="nav-link">
                    Cities
                  </NavLink>
                </li>
                <li className="nav-item col-6 col-md-auto">
                  <NavLink to="/cinema" className="nav-link">
                    Cinemas
                  </NavLink>
                </li>
                <li className="nav-item col-6 col-md-auto">
                  <NavLink to="/film" className="nav-link border-sm-0">
                    Films
                  </NavLink>
                </li>
                <li className="nav-item col-6 col-md-auto">
                  <NavLink to="/screening" className="nav-link">
                    Screenings
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          {hasData && (
            <ul className="navbar-nav  bd-navbar-nav eca-menu">
              <li className="nav-item col-6 col-md-auto">
                <NavLink
                  to="/visualisation"
                  className="nav-link eca-visualisation"
                >
                  Visualisations
                </NavLink>
              </li>
              <li className="nav-item col-6 col-md-auto">
                <NavLink to="/map" className="nav-link">
                  Maps
                </NavLink>
              </li>
              <li className="nav-item col-6 col-md-auto">
                <NavLink to="/top/film/all/all/1y/20" className="nav-link">
                  Top Films
                </NavLink>
              </li>
              <li className="nav-item col-6 col-md-auto">
                <NavLink to="/top/cinema/all/all/1y/20" className="nav-link">
                  Top Cinemas
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </>
  );
};
export default Navbar;
