import ResultList from "./results/ResultList";

const FilmList = () => {
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Films`;
  const entity = "film";

  return <ResultList entity={entity} title={title} />;
};

export default FilmList;
