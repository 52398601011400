import Navbar from "./Navbar";
const Header = ({ hasData }) => {
  return (
    <header className="page-header">
      {/* <div className="header-logo">
        <img src="/assets/images/logo.png" />
      </div> */}
      <div className="header-menu">
        <Navbar hasData={hasData} />
      </div>
    </header>
  );
};

export default Header;
