import { Link, useLocation } from "react-router-dom";

const ItemRow = ({ columns, item, showImage = false, showProject = true }) => {
  const location = useLocation();

  const fields = columns.map((column) => {
    let type = "string";

    if (column.property === "date") {
      type = "date";
    } else if (Array.isArray(item[column.property])) {
      type = "array";
    } else if (typeof item[column.property] === "number") {
      type = "number";
    } else if (typeof item[column.property] === "string") {
      type = "string";
    }

    let value = "";
    switch (type) {
      case "date":
        value = new Date(item[column.property]).toLocaleDateString();
        break;
      case "array":
        value = <span>item[column.property].join(", ")</span>;
        break;
      case "number":
        value = item[column.property].toLocaleString();
        break;
      case "string":
        value = column.property
          .split(".")
          .reduce((p, c) => (p && p[c]) || null, item);
        break;
      default:
        value = item[column.property];
        break;
    }
    const content = <span>{value}</span>;

    let path = "";
    switch (column.show) {
      // case 'screening':
      //   path = `/${column.show}/${item.id}?cinema=${item.cinemaId}&date=${item.dateIso}`;
      //   break;

      // case 'city':
      //   path = `/${column.show}/${item.displayName}`;
      //   break;

      default:
        path = `/${column.show}/${item[column.showSlug]}`;
        break;
    }
    return (
      <td key={`${item.id}-${column.key}`}>
        {column.show !== "" ? (
          <Link to={path}> {content}</Link>
        ) : (
          <>{content}</>
        )}
      </td>
    );
  });

  return (
    <tr>
      {/* {showImage && <td>{image}</td>} */}
      {process.env.REACT_APP_PROJECT_CODE === "ch" &&
        showProject &&
        location.pathname !== "/data-summary" && (
          <td>
            <Link to={`/project/${item.projectNameSlug}`}>
              {item.projectName}
            </Link>
          </td>
        )}
      {fields}
    </tr>
  );
};
export default ItemRow;
