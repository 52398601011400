import { useContext } from "react";

import { Link } from "react-router-dom";
import { useParams, useLocation, matchPath } from "react-router-dom";

import { Context } from "../Store";

const PeriodChoices = () => {
  const [state] = useContext(Context);
  const location = useLocation();
  const { n, period, city, project } = useParams();

  const buttons = state.timePeriods.map((choice) => {
    let pathname = "";
    if (
      matchPath("/top/cinema/:project/:city/:timePeriod/:n", location.pathname)
    ) {
      pathname = `/top/cinema/${project}/${city}/${choice.key}/${n}`;
    } else if (
      matchPath("/top/film/:project/:city/:timePeriod/:n", location.pathname)
    ) {
      pathname = `/top/film/${project}/${city}/${choice.key}/${n}`;
    } else if (matchPath("/price-proxy/:city", location.pathname)) {
      pathname = `/price-proxy/${choice.key}`;
    }

    return (
      <Link
        className={
          "btn btn-sm " +
          (period === choice.key ? "btn-secondary" : "btn-outline-secondary")
        }
        key={choice.key}
        to={{
          pathname: pathname,
          search: location.search,
        }}
      >
        {choice.name}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3">{buttons}</div>;
};
export default PeriodChoices;
