import L from "leaflet";

const CityNavigation = ({ map, cities, onClick }) => {
  cities.forEach((city) => {
    city.visible = map.getBounds().contains(L.latLng(city.center));
  });

  const cityButtons = cities.map((city) => {
    return (
      <li key={city.slug} className="nav-item">
        <button
          data-city={city.id}
          className={
            "btn btn-sm nav-city m-2" +
            (city.visible ? " btn-primary" : " btn-outline-primary")
          }
          onClick={() => onClick(city.slug)}
        >
          {city.name}
        </button>
      </li>
    );
  });

  return (
    <div className="">
      <div className="mb-2">
        <div
          className="map-heading map-collapsible-heading me-2"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-navigate-cities"
          aria-expanded="false"
          aria-controls="collapse-navigate-cities"
        >
          <span className="ms-2">Navigate to Cities</span>
        </div>
        <ul id="collapse-navigate-cities" className="nav me-1 collapse">
          {cityButtons}
        </ul>
      </div>
    </div>
  );
};

export default CityNavigation;
