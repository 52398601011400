import { useSearchParams, useParams } from "react-router-dom";

import TopFilmPeriods from "./TopFilmPeriods";
import useAxios from "../../hooks/useAxios";
import Error from "../../layout/Error";
import Loading from "../../layout/Loading";

const TopCinemaList = () => {
  const { project, city, period, n } = useParams();

  const [searchParams] = useSearchParams();

  const apiPath = `${process.env.REACT_APP_DATA_URL}/top/film/${project}/${city}/${period}/${n}`;
  const { data, isLoading, error } = useAxios(
    apiPath,
    [],
    [apiPath, searchParams.toString()]
  );
  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <>
                <TopFilmPeriods
                  periods={data.aggregations.time_period.buckets}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopCinemaList;
