import { useEffect } from "react";
import { useContext, useState } from "react";

import { useSearchParams } from "react-router-dom";

import useValidateDate from "./useValidateDate";
import { Context } from "../Store";

const useForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state] = useContext(Context);

  const initialDates = useValidateDate();

  const [values, setValues] = useState(initialDates);

  const handleChange = (event) => {
    let newValues = { ...values };
    newValues[event.target.name] = event.target.checked
      ? event.target.checked
      : event.target.value;
    setValues({ ...newValues });
  };
  const handleSelectChange = (value) => {
    let newValues = values;
    newValues[value.name] = value.values;
    setValues({ ...newValues });
  };

  const handleDateChange = (date) => {
    let newValues = { ...values };
    newValues[date.name] = date.value;

    setValues({ ...newValues });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const initialStringified = searchParams.toString();
    const newSearchParams = new URLSearchParams(searchParams);

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string" && "" !== values[key]) {
        newSearchParams.set(key, values[key]);
      } else if (values[key] instanceof Date) {
        if (
          (key === "dateFrom" && values[key] !== state.initialDates.dateFrom) ||
          (key === "dateTo" && values[key] !== state.initialDates.dateTo)
        ) {
          newSearchParams.set(key, values[key].toISOString().substring(0, 10));
        } else {
          newSearchParams.delete(key);
        }
      } else if (Array.isArray(values[key]) && values[key].length > 0) {
        //bracket style for PHP
        values[key].forEach((valueKey) => {
          newSearchParams.append(key + "[]", valueKey.value);
        });
      } else if (typeof values[key] === "boolean") {
        if (values[key]) {
          newSearchParams.set(key, values[key]);
        } else {
          newSearchParams.delete(key);
        }
      }
    });
    if (initialStringified !== newSearchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true });
    }
  };

  const handleReset = (event) => {
    let newValues = { ...values };
    Object.keys(newValues).forEach((key) => {
      if (typeof newValues[key] === "string" && "" !== newValues[key]) {
        newValues[key] = "";
      }
      // else if (newValues[key] instanceof Date) {
      //   if (key === 'dateFrom') {
      //     newValues[key] = new Date(initialValue.dateFrom);
      //   } else if (key === 'dateTo') {
      //     newValues[key] = new Date(initialValue.dateTo);
      //   } else {
      //     newValues[key] = null;
      //   }
      // }
      else if (Array.isArray(newValues[key])) {
        newValues[key] = [];
      } else if (typeof newValues[key] === "boolean") {
        newValues[key] = false;
      }
    });

    newValues.dateTo = new Date(state.initialDates.dateTo);
    newValues.dateFrom = new Date(state.initialDates.dateFrom);
    setValues(newValues);
  };

  return [
    values,
    handleChange,
    handleDateChange,
    handleSelectChange,
    handleSubmit,
    handleReset,
    state.initialDates,
  ];
};

export default useForm;
