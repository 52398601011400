import { useState, useEffect, useCallback } from 'react';

import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import { SliderRail, Handle, Track } from './DateSliderComponents';

const DateSlider = ({ daysIndex, firstActiveDateIndex, values, setValues }) => {
  const [domain, setDomain] = useState([0, daysIndex.length]);
  const [sliderValues, setSliderValues] = useState([0, daysIndex.length]);
  const [isAnimate, setIsAnimate] = useState(false);
  const [animateLabel, setAnimateLabel] = useState('Animate');

  const sliderStyle = {
    position: 'relative',
    width: '100%',
  };

  const animateRun = useCallback(async () => {
    const max = daysIndex.length;
    const step = 7;
    const frameTime = 5;

    let firstStart = 0;
    if (firstActiveDateIndex > 0) {
      firstStart = Math.floor(firstActiveDateIndex / step) * step;
    }
    const firstEnd = firstStart + 6;
    let values = [firstStart, firstEnd];

    while (values[1] + step <= max && isAnimate) {
      values = [values[0] + step, values[1] + step];
      setValues(values);

      await sleep(frameTime);
    }

    setIsAnimate(false);
  }, [daysIndex, isAnimate, firstActiveDateIndex, setValues, setIsAnimate]);

  const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    if (isAnimate) {
      setAnimateLabel('Stop');
      async function handleSlide() {
        const max = daysIndex.length;
        const step = 7;
        const frameTime = 50;

        let firstStart = 0;
        if (firstActiveDateIndex > 0) {
          firstStart = Math.floor(firstActiveDateIndex / step) * step;
        }
        const firstEnd = firstStart + 6;
        let values = [firstStart, firstEnd];

        while (values[1] + step <= max && isAnimate) {
          values = [values[0] + step, values[1] + step];

          setSliderValues(values);

          await sleep(frameTime);
        }
        setIsAnimate(false);
      }

      handleSlide();
    } else {
      setAnimateLabel('Animate');
    }
  }, [isAnimate, animateRun]);

  return (
    <div className="row">
      <div className="col-md-10">
        <div style={{ margin: '2% 5%', height: 50, width: '90%' }}>
          <Slider
            mode={3}
            step={7}
            domain={domain}
            rootStyle={sliderStyle}
            onChange={values => setValues(values)}
            values={sliderValues}
          >
            <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </div>
      </div>
      <div className="col-md-2">
        <button
          style={{
            margin: '5% 0%',
          }}
          className="btn btn-primary btn-sm"
          onClick={() => setIsAnimate(!isAnimate)}
        >
          {animateLabel}
        </button>
      </div>
    </div>
  );
};

export default DateSlider;
