import { useContext } from "react";

import { Link, useLocation } from "react-router-dom";

import { Context } from "../../Store";

const CityCompareChoices = ({ city, cityDisplayName, film, compare }) => {
  const [state] = useContext(Context);

  const location = useLocation();
  let compareLink = null;
  let linkText = "";
  if (city != "all") {
    state.cities.forEach((choice) => {
      if (
        city !== city.slug &&
        choice.slug !== "all" &&
        compare[choice.slug] > 0
      ) {
        linkText += `${choice.name} (${compare[choice.slug]}) `;
      }
    });

    linkText = linkText.trim();

    if (linkText === "") {
      compareLink = "Only shown in " + cityDisplayName;
    } else {
      compareLink = (
        <div>
          <Link
            className="btn btn-sm btn-success"
            to={"/top/film/cinema/compare/" + city + "/" + film}
            search={location.search}
          >
            {"Compare with: " + linkText}
          </Link>
        </div>
      );
    }
  }

  return <div className="">{compareLink}</div>;
};

export default CityCompareChoices;
