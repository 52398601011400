import React from "react";

import PropTypes from "prop-types";

const SimpleTable = ({ data, filterName }) => {
  const rows = data.map((datum, index) => {
    return (
      <tr key={index}>
        <td>{datum.key}</td>
        <td>{datum.doc_count.toLocaleString()}</td>
      </tr>
    );
  });
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>{filterName}</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

SimpleTable.propTypes = {
  data: PropTypes.array,
  filterName: PropTypes.string,
};

export default SimpleTable;
