import ResultList from "./results/ResultList";

const CompanyList = () => {
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Companies`;
  const entity = "company";

  return <ResultList entity={entity} title={title} />;
};

export default CompanyList;
