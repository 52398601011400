import { useRecordContext } from "react-admin";
const ImageOptions = () => {
  const record = useRecordContext();

  if (record && record.src) {
    return (
      <span>
        <img
          src={record.src}
          alt={record.originalImageName}
          width="20"
          className="img-fluid me-3"
        />
        {record.originalImageName}
      </span>
    );
  } else {
    return null;
  }
};

export default ImageOptions;
