const DOMPurify = require("dompurify")(window);

const HtmlSnippet = ({ snippet, element = "div", className = "" }) => {
  const cleanHtml = DOMPurify.sanitize(snippet);
  if (element === "span") {
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{ __html: cleanHtml }}
      ></span>
    );
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    ></div>
  );
};
export default HtmlSnippet;
