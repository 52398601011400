const NotFound = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - 404 Not Found`;

  return (
    <>
      <div className="mt-4">
        <h1>ECA - 404 - Not Found!</h1>
      </div>
      <div className="row">
        <div className="col">
          <p>404 - Not Found!</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
