import { useOutletContext } from "react-router-dom";

import CinemaDetail from "./cinema/CinemaDetail";

const CinemaShow = () => {
  const [cinema, data, cinemaAggregations] = useOutletContext();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Cinemas: ${cinema.name}`;

  return (
    <div className="">
      {cinema && (
        <CinemaDetail
          cinema={cinema}
          cinemaAggregations={cinemaAggregations}
          screenings={data.screenings}
          linkAggregations={data.linkAggregations}
          aggregations={data.aggregations}
        />
      )}
    </div>
  );
};

export default CinemaShow;
