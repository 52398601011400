import React from "react";

import PropTypes from "prop-types";
import {
  Tooltip,
  Pie,
  PieChart,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

import { COLORS } from "../../utils/colors";

const SimplePie = ({ data, filterName }) => {
  const sliceSize = 10;

  if (data.length > sliceSize) {
    data.sort((a, b) => b.doc_count - a.doc_count);

    const other = {
      key: "Other",
      doc_count: data
        .slice(sliceSize)
        .map((d) => d.doc_count)
        .reduce((a, b) => a + b),
    };
    data.slice(0, sliceSize);
    // data.sort((a, b) => a.key.localeCompare(b.key));
    data = [...data.slice(0, sliceSize), other];
  }

  const style = {
    padding: 6,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
  };

  const SimpleTooltip = ({ active, payload, filterName }) => {
    if (active) {
      const value = payload && payload.length ? payload[0].payload : null;
      return (
        <div className="area-chart-tooltip" style={style}>
          <p>
            <strong>{filterName + " : "}</strong>
            <span>{value ? value.key : " -- "}</span>{" "}
            <em>{value ? value.doc_count.toLocaleString() : " -- "}</em>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Legend layout="vertical" verticalAlign="top" align="top" />

        <Pie
          dataKey="doc_count"
          nameKey="key"
          data={data}
          innerRadius={100}
          label={(val) => val.doc_count.toLocaleString()}
          legendType="square"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<SimpleTooltip filterName={filterName} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

SimplePie.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.array,
  data: PropTypes.array,
  filterName: PropTypes.string,
};

export default SimplePie;
