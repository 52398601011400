import { useState, useEffect } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";

const useAxios = (apiPath, query = [], watchChange = []) => {
  const location = useLocation();
  // const parsed = queryString.parse(location.search);
  // const queryParams = { ...parsed, ...query };
  // const stringified = queryString.stringify(queryParams);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState({ code: null, message: null });

  const fetchData = () => {
    axios
      .get(`${apiPath}${location.search}`, { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            setError({
              code: error.response.status,
              message: error.response.data.error,
            });
          } else {
            setError({
              code: error.response.status,
              message: "An error has occured.",
            });
          }
        } else if (("request", error.request)) {
          setError({ code: 500, message: error.request });
        } else {
          console.log("other", error.message);
          setError({ code: 500, message: error.message });
        }
        setData({ ...data, items: [] });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    return () => {
      setData([]);
      setIsLoading(false);
      setError({ code: null, message: null });
    };
  }, watchChange);

  return {
    data,
    isLoading,
    error,
  };
};

export default useAxios;
