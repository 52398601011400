import { useEffect } from "react";

import { useParams } from "react-router-dom";

import FilmDetail from "./film/FilmDetail";
import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const FilmShow = () => {
  const { slug } = useParams();

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Films`;

  const apiPath = `${process.env.REACT_APP_DATA_URL}/film/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath, [], [apiPath]);
  useEffect(() => {
    if (data.film) {
      document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Films: ${data.film.name}`;
    }
  }, [data]);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {data.item && (
                <FilmDetail
                  film={data.item}
                  screenings={data.data.screenings}
                  linkAggregations={data.data.linkAggregations}
                  aggregations={data.data.aggregations}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FilmShow;
