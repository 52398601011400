import { useState } from "react";

import {
  AdminGuesser,
  hydraDataProvider,
  HydraAdmin,
  hydraSchemaAnalyzer,
  FieldGuesser,
  ListGuesser,
  ResourceGuesser,
  EditGuesser,
  InputGuesser,
  CreateGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import PostIcon from "@mui/icons-material/Book";
import ImageIcon from "@mui/icons-material/Image";
import UserIcon from "@mui/icons-material/People";
import axios from "axios";
import { RichTextInput } from "ra-input-rich-text";
import {
  Edit,
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  Filter,
  ReferenceField,
  ReferenceInput,
  TextField,
  AutocompleteInput,
  UrlField,
  RichTextField,
  NumberInput,
  ImageField,
  FunctionField,
  SelectInput,
  Datagrid,
} from "react-admin";

import CKEditorField from "./CKEditorField";
import ImageOptions from "./ImageOptions";
import ImageValues from "./ImageValues";
import Loading from "../../layout/Loading";

const CityFilter = (
  <Filter>
    <ReferenceInput
      label="City Name"
      source="city"
      reference="cities"
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ImagesList = (props) => (
  <ListGuesser {...props}>
    <ImageField source="src" title="title" label="Image" />
    <FieldGuesser source="originalImageName" label="Original Name" />
    <UrlField source="src" label="Link" />
  </ListGuesser>
);

const ImageCreate = (props) => (
  <CreateGuesser {...props}>
    <FileInput source="file">
      <FileField source="src" title="title" />
    </FileInput>
  </CreateGuesser>
);

const ImageShow = (props) => (
  <ShowGuesser {...props}>
    <ImageField source="src" title="title" label="Image" />
    <UrlField source="src" label="Link" />
    <FieldGuesser source="originalImageName" label="Original Name" />{" "}
  </ShowGuesser>
);
const TeamsList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="sortOrder" />
    <ImageField source="image.src" label="Image" />
    <FieldGuesser source="name" />
    <RichTextField source="description" />
  </ListGuesser>
);

const TeamCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" fullWidth resettable />
    <CKEditorField source="description" fullWidth />
    <NumberInput source="sortOrder" />
    <ReferenceInput source="image" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </CreateGuesser>
);

const TeamEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" fullWidth resettable />
    <CKEditorField source="description" fullWidth />
    <NumberInput source="sortOrder" />
    {/* <ReferenceInput source="image.@id" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput> */}
    <ReferenceInput source="image" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </EditGuesser>
);

const TeamShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="sortOrder" />
    <FieldGuesser source="name" />
    <ImageField source="image.src" label="Image" />
    <RichTextField source="description" />
  </ShowGuesser>
);

const ProjectsList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="sortOrder" />
    <ImageField source="image.src" label="Image" />
    <FieldGuesser source="code" />
    <FieldGuesser source="name" />
    <UrlField source="url" />
  </ListGuesser>
);

const ProjectCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="code" disabled />
    <InputGuesser source="name" fullWidth resettable />
    <InputGuesser source="hasData" />
    <InputGuesser source="sortOrder" />
    <InputGuesser source="url" fullWidth />
    <InputGuesser source="path" fullWidth label="Microsite Homepage" />
    <RichTextInput source="description" fullWidth />
    <RichTextInput source="shortDescription" fullWidth />
    <ReferenceInput source="image" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </CreateGuesser>
);

const ProjectEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="code" disabled />
    <InputGuesser source="name" fullWidth resettable />
    <InputGuesser source="hasData" />
    {/* <NumberInput source="sortorder" /> */}
    <InputGuesser source="sortOrder" />
    <InputGuesser source="url" fullWidth />
    <InputGuesser source="path" fullWidth label="Microsite Homepage" />
    <CKEditorField source="description" fullWidth />
    <CKEditorField source="shortDescription" fullWidth />
    <ReferenceInput source="image" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </EditGuesser>
);

const ProjectShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="sortOrder" />
    <FieldGuesser source="code" />
    <FieldGuesser source="name" />
    <FieldGuesser source="hasData" />
    <UrlField source="url" />
    <UrlField source="path" label="Microsite Homepage" />
    <RichTextField source="description" />
    <RichTextField source="shortDescription" />
    <ImageField source="image.src" label="Image" />
  </ShowGuesser>
);

const PagesList = (props) => (
  <ListGuesser {...props} bulkActionButtons={false}>
    <FieldGuesser source="name" />
    <ImageField source="image.src" label="Image" />
  </ListGuesser>
);

const PageCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" fullWidth disabled />
    <CKEditorField source="description" fullWidth />
    <ReferenceInput source="image" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </CreateGuesser>
);

const PageEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" fullWidth disabled />
    <CKEditorField source="description" fullWidth />
    <ReferenceInput source="image.@id" reference="images">
      <SelectInput
        optionText={<ImageOptions />}
        optionValue="@id"
        label="Image"
      />
    </ReferenceInput>
  </EditGuesser>
);

// const PageEdit = (props) => (
//   <Edit {...props}>
//     <SimpleForm>
//       <TextInput source="name" fullWidth disabled />
//       <CKEditorField source="description" fullWidth />
//       <ReferenceInput source="image.@id" reference="images">
//         <SelectInput
//           optionText={<ImageOptions />}
//           optionValue="@id"
//           label="Image"
//         />
//       </ReferenceInput>
//     </SimpleForm>
//   </Edit>
// );

const PageShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" />
    <RichTextField source="description" />
    <ImageField source="image.src" label="Image" />
  </ShowGuesser>
);

const FilmsList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="title" />
  </ListGuesser>
);

const CinemasList = ({ ...props }) => (
  //replace filter guesser
  <ListGuesser {...props} filters={CityFilter}>
    <FieldGuesser source="name" />
    <FieldGuesser source="address" />
    <ReferenceField
      label="City"
      source="city.@id"
      reference="cities"
      sortBy="city.name"
    >
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

const CensorshipChurchList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CensorshipStateList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CitiesList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CinemaLocationList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CinemaSeatingRangeList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CinemaTypeList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const GenreList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const CountryList = (props) => (
  <ListGuesser {...props}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

const ScreeningList = (props) => (
  <ListGuesser {...props}>
    <ReferenceField
      label="Project Name"
      source="project.@id"
      reference="projects"
    >
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="date" />
    <ReferenceField label="Cinema" source="cinema.@id" reference="cinemas">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Film" source="film.@id" reference="films">
      <TextField source="title" />
    </ReferenceField>
    <FieldGuesser source="titleVariation" />
    <ReferenceField
      label="Censorship Church"
      source="censorshipChurch.@id"
      reference="films"
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Censorship State"
      source="censorshipState.@id"
      reference="films"
    >
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

const AdminHome = () => {
  const [userType, setUserType] = useState(false);

  const url = `${process.env.REACT_APP_HOMEPAGE}/user-type`;

  axios.get(url, { withCredentials: true }).then((res) => {
    if (url !== res.request.responseURL) {
      window.location.replace(process.env.REACT_APP_HOMEPAGE);
    }

    res.data.isAdmin
      ? setUserType(true)
      : window.location.replace(process.env.REACT_APP_HOMEPAGE);
  });

  const dataProvider = hydraDataProvider({
    entrypoint: process.env.REACT_APP_API_URL,
  });
  const schemaAnalyzer = hydraSchemaAnalyzer();

  return userType ? (
    // <AdminGuesser
    //   dataProvider={dataProvider}
    //   schemaAnalyzer={schemaAnalyzer}
    //   basename="/admasdasin"
    // />
    //basename  prop is not sported by latest rebase but is fixed in upstream repo waiting for new release
    <HydraAdmin
      entrypoint={`${process.env.REACT_APP_API_URL}`}
      basename="/admin"
    >
      {process.env.REACT_APP_PROJECT_CODE === "ch" && (
        <ResourceGuesser
          name="projects"
          list={ProjectsList}
          edit={ProjectEdit}
          create={ProjectCreate}
          show={ProjectShow}
        />
      )}
      <ResourceGuesser
        name="pages"
        icon={PostIcon}
        list={PagesList}
        edit={PageEdit}
        create={PageCreate}
        show={PageShow}
      />
      <ResourceGuesser
        name="teams"
        options={{ label: "Team Members" }}
        icon={UserIcon}
        list={TeamsList}
        edit={TeamEdit}
        create={TeamCreate}
        show={TeamShow}
      />
      <ResourceGuesser
        name="images"
        icon={ImageIcon}
        list={ImagesList}
        // edit={ImageEdit}
        create={ImageCreate}
        show={ImageShow}
      />
      {/* <ResourceGuesser name="cities" list={CitiesList} />
  <ResourceGuesser name="cinemas" list={CinemasList} />
  <ResourceGuesser name="films" list={FilmsList} />
  <ResourceGuesser name="screenings" list={ScreeningList} />
  <ResourceGuesser
    name="cinema_locations"
    list={CinemaLocationList}
    options={{ label: "Cinema Location" }}
  />
  <ResourceGuesser
    name="cinema_seating_ranges"
    list={CinemaSeatingRangeList}
    options={{ label: "Cinema Seating Range" }}
  />
  <ResourceGuesser
    name="cinema_types"
    list={CinemaTypeList}
    options={{ label: "Cinema Type" }}
  />
  <ResourceGuesser name="genres" list={GenreList} />
  <ResourceGuesser name="countries" list={CountryList} />
  <div>Lists</div>

  <ResourceGuesser
    name="censorship_churches"
    list={CensorshipChurchList}
    options={{ label: "Censorship Church" }}
  />
  <ResourceGuesser
    name="censorship_states"
    list={CensorshipStateList}
    options={{ label: "Censorship State" }}
  /> */}
    </HydraAdmin>
  ) : (
    <Loading />
  );
};

export default AdminHome;
