import ResultList from "./results/ResultList";

const CinemaList = () => {
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Cinemas`;
  const entity = "cinema";

  return <ResultList entity={entity} title={title} />;
};

export default CinemaList;
