import { useSearchParams } from "react-router-dom";

import useAxios from "./useAxios";

const useResults = (apiPath) => {
  const [searchParams] = useSearchParams();

  const exclude = ["lat", "lng", "zoom", "markerType"];
  exclude.forEach((key) => {
    searchParams.delete(key);
  });
  const { data, isLoading, error } = useAxios(
    apiPath,
    [],
    [apiPath, searchParams.toString()]
  );

  return {
    items: data.items ? data.items : [],
    aggregations: data.aggregations ? data.aggregations : [],
    nestedAggregations: data.nestedAggregations ? data.nestedAggregations : {},
    pagination: data.pagination ? data.pagination : {},
    columns: data.columns ? data.columns : {},
    isLoading: isLoading,
    error: error,
  };
};

export default useResults;
