import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import useFilters from "../hooks/useFilters";

const AggregationBucket = ({
  aggregationName,
  aggregationLabel,
  name,
  aggregationKey,
  count,
  // pathname,
  reverse,
  color,
}) => {
  const [searchParams, remove] = useFilters(aggregationName, aggregationKey);
  return (
    <>
      {/* <li className="list-group-item"> */}
      <div className="col-12">
        <span
          className="me-2"
          style={{
            backgroundColor: color,
            width: "1em",
            height: "1em",
            display: "inline-block",
          }}
        ></span>
        {/* {count === 0 ? (
          <span className={`text-break text-muted`}>
            {name} ({count})
          </span>
        ) : (
          <Link
            className={`text-break link-primary`}
            to={{
              search: searchParams.toString(),
            }}
          >
            {name} ({count}) {remove && <span>[x]</span>}
          </Link>
        )} */}
        <Link
          className={`text-break link-primary`}
          to={{
            search: searchParams.toString(),
          }}
        >
          {name} ({count.toLocaleString()}) {remove && <span>[x]</span>}
        </Link>
      </div>
      {/* </li> */}
    </>
  );
};

AggregationBucket.propTypes = {
  aggregationName: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  pathname: PropTypes.string,
};

export default AggregationBucket;
