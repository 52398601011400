import ResultList from "./results/ResultList";

const PersonList = () => {
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - People`;
  const entity = "person";

  return <ResultList entity={entity} title={title} />;
};

export default PersonList;
