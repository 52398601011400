import { useContext } from "react";

import { Link } from "react-router-dom";
import { useParams, useLocation, matchPath } from "react-router-dom";

import { Context } from "../Store";

const CityChoices = () => {
  const [state] = useContext(Context);
  const location = useLocation();
  const { n, period, city, project } = useParams();

  const buttons = state.navigationCities.map((choice) => {
    let pathname = "";
    if (
      matchPath("/top/cinema/:project/:city/:timePeriod/:n", location.pathname)
    ) {
      pathname = `/top/cinema/${project}/${choice.key}/${period}/${n}`;
    } else if (matchPath("/price-proxy/:city", location.pathname)) {
      pathname = `/price-proxy/${choice.key}`;
    } else if (
      matchPath("/top/film/:project/:city/:timePeriod/:n", location.pathname)
    ) {
      pathname = `/top/film/${project}/${choice.key}/${period}/${n}`;
    } else if (
      matchPath(
        "/top/cinema/:project/:city/:timePeriod/:n/map",
        location.pathname
      )
    ) {
      pathname = `/top/cinema/${project}/${choice.key}/${period}/${n}/map`;
    } else if (
      matchPath("/visualisation/timeline/film/cinema/:city", location.pathname)
    ) {
      pathname = `/visualisation/timeline/film/cinema/${choice.key}`;
    }
    return (
      <Link
        className={
          "btn btn-sm " +
          (city === choice.key ? "btn-success" : "btn-outline-success")
        }
        key={choice.key}
        to={{
          pathname: pathname,
          search: location.search,
        }}
        style={{ color: choice.projectColor }}
      >
        {choice.name}
      </Link>
    );
  });
  return <div className="btn-group me-2 mb-3 flex-wrap">{buttons}</div>;
};
export default CityChoices;
