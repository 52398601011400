import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import PropTypes from "prop-types";
import { FieldTitle, useInput } from "react-admin";

const CKEditorField = ({ label, source }) => {
  const { field, isRequired } = useInput({ source });

  const handleChange = (event, editor) => {
    const data = editor.getData();
    field.onChange(data);
  };

  return (
    <div className="w-100">
      <label>
        <FieldTitle
          label={label}
          source={source}
          resource={field}
          isRequired={isRequired}
        />
      </label>
      <CKEditor
        editor={ClassicEditor}
        config={{
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
            ],
          },
        }}
        data={field.value}
        onChange={handleChange}
      />
    </div>
  );
};

CKEditorField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default CKEditorField;
