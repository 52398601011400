import ResultList from "./results/ResultList";

const ScreeningList = () => {
  const title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Screenings`;
  const entity = "screening";

  return <ResultList entity={entity} title={title} />;
};

export default ScreeningList;
