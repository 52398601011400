import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const Copyright = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Copyright and Permissions`;
  const url = `${process.env.REACT_APP_HOMEPAGE}/page/copyright`;
  const { data, isLoading, error } = useAxios(url, [], []);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              <div className="full-page row">
                <div className="col-6 h-100 d-flex align-items-center">
                  {data.image && (
                    <img
                      className="border rounded-5 mx-auto"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={data.image.src}
                      alt="copyright tile"
                    />
                  )}
                </div>
                <div className="col-6 ch-overflow-y h-100 text-break">
                  {/* <h1 className="">Copyright and Permissions</h1> */}
                  {data.description && (
                    <div className="row">
                      <HtmlSnippet snippet={data.description} className="" />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Copyright;
