import PropTypes from "prop-types";
import { Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";

// import CinemaMarker from "./CinemaMarker";
const SearchMarkers = ({ markerType, cinemas }) => {
  const markers = cinemas.map((cinema) => {
    const position = [cinema.geoLocation.lat, cinema.geoLocation.lon];
    return (
      <Marker key={cinema.slug} position={position}>
        <Popup>
          <Link to={`/cinema/${cinema.slug}`}>{cinema.name}</Link>
        </Popup>
      </Marker>
    );
  });
  return markers;
};

SearchMarkers.prototype = {
  markerType: PropTypes.string,
  cinema: PropTypes.array,
};

export default SearchMarkers;

// <CinemaMarker
//   key={cinema.id}
//   cinema={cinema}
//   markerType={markerType}
// ></CinemaMarker>
