import { timeFormat } from "d3-time-format";
import { Link } from "react-router-dom";
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
} from "recharts";

const ScreeningAggregationChart = ({ aggregation }) => {
  const dateFormatString = "%e %B %Y";

  const TiltedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div
      key={aggregation.key}
      className="mt-4 col-12 col-md-6 col-lg-4 col-xl-3"
    >
      <h2 className="mb-2">
        <small>{aggregation.name}</small>
      </h2>
      <ResponsiveContainer width={"100%"} height={400}>
        <BarChart
          width={500}
          height={300}
          data={aggregation.values.slice(0, 10)}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 180,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            type="category"
            interval={0}
            tickFormatter={timeFormat(dateFormatString)}
            tick={TiltedAxisTick}
          />
          <YAxis
            label={{
              value: "Total Screenings",
              offset: 10,
              angle: -90,
              position: "insideBottomLeft",
            }}
          />
          <Tooltip />
          <Bar dataKey="screenings" fill="#6c757d" />
        </BarChart>
      </ResponsiveContainer>
      <div className="mt-5" style={{ height: "400px", overflowY: "scroll" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="col-5">{aggregation.name}</th>
              <th className="col-5">Total Screenings</th>
              <th className="col-2"></th>
            </tr>
          </thead>
          <tbody>
            {aggregation.values.map((value) => (
              <tr key={value.id + value.name}>
                <td>
                  {value.id ? (
                    <Link to={`/${aggregation.key}/${value.id}`}>
                      {value.name}
                      {value.type && ` (${value.type})`}
                    </Link>
                  ) : (
                    <>
                      {value.name}
                      {value.type && ` (${value.type})`}
                    </>
                  )}
                </td>
                <td>{value.screenings.toLocaleString()}</td>
                <td>
                  <Link to={`/screening?${aggregation.key}[]=${value.name}`}>
                    Screenings
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScreeningAggregationChart;
