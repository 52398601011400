import { useSearchParams } from "react-router-dom";

const useFilters = (aggregationName, aggregationValue) => {
  const [searchParams] = useSearchParams();
  const queryField = `${aggregationName}[]`;
  let remove = false;

  const newSearchParams = new URLSearchParams(searchParams);
  const activeFilters = newSearchParams.getAll(queryField);
  const bucketFilter = aggregationValue;

  if (activeFilters.includes(bucketFilter)) {
    remove = true;
    let newFilters = activeFilters.filter((filter) => filter !== bucketFilter);
    newSearchParams.delete(queryField);
    newFilters.forEach((filter) => newSearchParams.append(queryField, filter));
  } else {
    newSearchParams.append(queryField, bucketFilter);
  }

  return [newSearchParams, remove];
};

export default useFilters;
