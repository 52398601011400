import { Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";

import TopCinemaContainer from "../../map/Container/TopCinemaContainer";
const TopCinemaMapDetails = ({ cinemas }) => {
  const markers = cinemas.map((cinema) => {
    const position = [cinema.geoLocation.lat, cinema.geoLocation.lon];
    return (
      <Marker key={cinema.slug} position={position}>
        <Popup>
          <Link to={`/cinema/${cinema.slug}`}>{cinema.name}</Link>
        </Popup>
      </Marker>
    );
  });

  return (
    <>
      <div className="">
        <TopCinemaContainer markers={markers} />
      </div>
    </>
  );
};

export default TopCinemaMapDetails;
