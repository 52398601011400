import React, { useEffect, useContext } from "react";

import axios from "axios";
import { useLocation, Outlet } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header.jsx";
import Loading from "./Loading";
import { Context } from "../Store";

const Page = () => {
  const location = useLocation();
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    const url = `${process.env.REACT_APP_DATA_URL}/store`;
    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const initialDatesString = res.data.initialDates;
        const initialDates = {
          dateFrom: new Date(initialDatesString.from),
          dateTo: new Date(initialDatesString.to),
        };
        const data = { ...res.data, initialDates, isLoading: false };
        dispatch({ type: "SET_DATA", payload: data });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERROR", payload: error });
      });
  }, [dispatch]);

  const projectCode = process.env.REACT_APP_PROJECT_CODE;
  const project = state.projects.find(
    (project) => project.code === projectCode
  );
  const hasData = project?.hasData;
  return (
    <>
      <Header
        hasData={process.env.REACT_APP_PROJECT_CODE === "ch" || hasData}
      />
      <main className="page-main container-fluid my-4">
        {state.isLoading ? <Loading /> : <Outlet />}
      </main>
      <Footer />
    </>
  );
};

export default Page;
