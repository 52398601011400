import ScreeningAggregationChart from "../results/show/chart/ScreeningAggregationChart";
import ScreeningByCityChart from "../results/show/chart/ScreeningsByCityChart";
import MostPopular from "../results/show/MostPopular";

const CityDetail = ({ city, screenings, linkAggregations, aggregations }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="d-inline">{city.name}</h1>
        </div>
      </div>

      <MostPopular
        linkAggregations={linkAggregations}
        aggregations={aggregations}
      />

      <ScreeningByCityChart screenings={screenings} />

      <div className="row">
        {linkAggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
        {aggregations
          .filter((aggregation) => aggregation.values.length > 0)
          .map((aggregation) => (
            <ScreeningAggregationChart
              key={aggregation.key}
              aggregation={aggregation}
            />
          ))}
      </div>
    </>
  );
};
export default CityDetail;
