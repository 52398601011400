import { useContext } from "react";

import { useParams } from "react-router-dom";

import TopFilmListItem from "./TopFilmListItem";
import { Context } from "../../Store";
import { formatTimePeriod } from "../../utils/dates";
import TopScreeningBar from "../chart/TopScreeningBar";

const TopFilmPeriods = ({ periods }) => {
  const { city, period, n, rank } = useParams();

  const [state, dispatch] = useContext(Context);

  const timePeriodListItems = periods.map((singlePeriod) => {
    const filmData = singlePeriod.film_id.buckets.map((film) => ({
      Label: film.film_title.buckets[0].key,
      Screenings: film.doc_count,
    }));

    const formattedTimePeriod = formatTimePeriod(
      singlePeriod.key_as_string,
      period,
      state.initialDates.dateFrom,
      state.initialDates.dateTo
    );

    if (singlePeriod.film_id.buckets.length === 0) {
      return null;
    }

    return (
      <li key={singlePeriod.key_as_string}>
        <h2>{formattedTimePeriod.displayDate}</h2>

        <div className="row">
          <div className="col-sm-6">
            <TopFilmListItem
              formattedTimePeriod={formattedTimePeriod}
              films={singlePeriod.film_id.buckets}
            />
          </div>
          <div className="col-sm-6">
            <TopScreeningBar data={filmData} />
          </div>
        </div>
      </li>
    );
  });

  return <ul className="list-unstyled">{timePeriodListItems}</ul>;
};

export default TopFilmPeriods;
