import spinner from "../images/spinner.gif";
const Loading = () => {
  return (
    <div className="loading-placeholder">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center mt-4 pt-4 mb-4 pb-4 display-4">
            {/* <i className="fa fa-film fa-spin fast-spin"></i> */}
            <img width="100" src={spinner} alt="loading" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
