// import  { useState } from 'react';

import BaseContainer from "./BaseContainer";
import TopFilmCinemaMarkers from "../Marker/TopFilmCinemaMarkers";

const TopFilmCinemaRunContainer = ({ cinemas, dateFrom, dateTo }) => {
  // const [markerType, setmarkerType] = useState('thumbnail');

  // const handleMarkerType = newmarkerType => {
  //   setmarkerType(newmarkerType);
  // };

  return (
    <BaseContainer
      showMarkerNav={false}
      // handleMarkerType={handleMarkerType}
      showCityNav={false}
      showAggregations={false}
      showCities={false}
      mapHeight="400px"
      markers={
        <TopFilmCinemaMarkers
          cinemas={cinemas}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      }
    />
  );
};

export default TopFilmCinemaRunContainer;
