import useAxios from "../../hooks/useAxios";
import Error from "../../layout/Error";
import Loading from "../../layout/Loading";

const HomeProjects = () => {
  const projectUrl = `${process.env.REACT_APP_HOMEPAGE}/project`;
  const {
    data: projectData,
    isLoading: projectIsLoading,
    error: projectError,
  } = useAxios(projectUrl, [], []);

  return (
    <div className="container-fluid">
      {projectIsLoading ? (
        <Loading />
      ) : (
        <>
          {projectError.message !== null || projectError.message !== null ? (
            <>
              <Error error={projectError} />
              <Error error={projectError} />
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 col-xl-12 col-xxl-10 mx-auto">
                  <div className="row">
                    {projectData
                      .filter((project) => project.code !== "ch")
                      .map((project) => {
                        const tileContent = (
                          <>
                            {project.image?.src && (
                              <img
                                src={project.image.src}
                                className="img-fluid rounded-5"
                                alt="project tile"
                                style={{ filter: "grayscale(1)" }}
                              />
                            )}
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <div
                                className={`rounded-5 p-3 text-decoration-none fs-5 `}
                                style={{
                                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                                  backdropFilter: "blur(5px)",
                                  // width: "max-content",
                                  lineHeight: "1",
                                  textWrap: "pretty",
                                  color:
                                    project.code === "ch" ? "red" : "white",
                                }}
                              >
                                {project.name}
                              </div>
                            </div>
                          </>
                        );
                        return (
                          <div
                            key={project.slug}
                            className="position-relative col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 cursor-pointer mb-3"
                          >
                            {project.path !== "#" ? (
                              <a
                                href={project.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {tileContent}
                              </a>
                            ) : (
                              <>{tileContent}</>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HomeProjects;
