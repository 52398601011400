import { Link, useSearchParams, useLocation } from "react-router-dom";
{
  /* <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" /> */
}
{
  /* <FontAwesomeIcon icon="fa-solid fa-arrow-down-short-wide" /> */
}
const TableHeading = ({ columns, showImage = false, showProject = true }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const headings = columns.map((column) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const direction = searchParams.get("direction") || "asc";
    const active = searchParams.get("sort") === column.key;
    const newDirection = active && direction === "asc" ? "desc" : "asc";
    const iconClass =
      newDirection === "asc"
        ? "fa fa-arrow-down-wide-short"
        : "fa fa-arrow-down-short-wide";
    newSearchParams.set("sort", column.key);
    newSearchParams.set("direction", newDirection);

    return (
      <th key={column.key}>
        <Link
          className={`${active ? "active" : ""}`}
          to={{
            search: newSearchParams.toString(),
          }}
        >
          {column.name}{" "}
          {iconClass !== "" && <i className={`fas ${iconClass}`}></i>}
        </Link>
      </th>
    );
  });
  return (
    <thead>
      <tr>
        {/* {showImage && <th></th>} */}
        {process.env.REACT_APP_PROJECT_CODE === "ch" &&
          showProject &&
          location.pathname !== "/data-summary" && <td>Project</td>}

        {headings}
      </tr>
    </thead>
  );
};
export default TableHeading;
