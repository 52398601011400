import React from "react";

import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// import { colors } from '../../utils/colors';

const TopScreeningBar = ({ data }) => {
  const TiltedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="Label"
          interval={0}
          tick={<TiltedAxisTick />}
          height={200}
        />
        <YAxis />
        <Tooltip />
        <Bar dataKey="Screenings" fill="#6c757d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

TopScreeningBar.propTypes = {
  data: PropTypes.array,
};

export default TopScreeningBar;
