import EcaContainer from "../map/Container/EcaContainer";
// import SearchHeader from "./search/SearchHeader";

const Map = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Map`;

  return (
    <div style={{}}>
      <EcaContainer />
    </div>
  );
};

export default Map;
