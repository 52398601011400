import CinemaCircleMarker from "./CinemaCircleMarker";

function TopFilmCinemaMarkers({ cinemas, dateFrom, dateTo }) {
  const markers = Object.values(cinemas).map((cinema, index) => {
    return (
      <CinemaCircleMarker
        key={cinema.key}
        index={index}
        cinema={cinema}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    );
  });

  return markers;
}

export default TopFilmCinemaMarkers;
